import Week from "./Week";
import ColorsGame from "./ColorsGame";

const Private = () => {
    return (
        <>
            <ColorsGame />
            <br />
            <Week />
        </>
    )
};

export default Private;
