import {useState} from "react";
import party from "party-js";

let i = 0

const COLORS = [
    { index: ++i, name: 'Green', cssColor: 'limegreen', audio: 'green.mp3' },
    { index: ++i, name: 'Red', cssColor: 'red', audio: 'red.mp3' },
    { index: ++i, name: 'Blue', cssColor: 'dodgerblue', audio: 'blue.mp3' },
    { index: ++i, name: 'Orange', cssColor: 'orange', audio: 'orange.mp3' },
    { index: ++i, name: 'Black', cssColor: 'black', audio: 'black.mp3' },
]

const colorAudioId = (color) => { return `${color.name}-audio` }
const targetColorElId = 'target-color'
const koAudioElId = 'ko-audio'
const shuffleColors = () => {
    return COLORS.map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)
}

function Color(props) {
    const color = props.color

    return <>
        <button style={
            {
                cursor: 'pointer',
                width: '5rem',
                height: '5rem',
                backgroundColor: color.cssColor,
                textAlign: 'center',
                margin: '1rem',
                padding: '1rem',
                borderRadius: '10px'
            }
        }
            disabled={props.disabled}
            onClick={e => props.onClicked()}>
        </button>
    </>
}
const playColorSound = (c) => {
    document.querySelector(`#${colorAudioId(c)}`).play()
}
const pickFirstRandomColor = () => shuffleColors()[0]

function ColorsGame() {
    const [targetColor, setTargetColor] = useState(pickFirstRandomColor())
    const [shuffledColors, setShuffledColors] = useState(shuffleColors())
    const [colorButtonsDisabled, setColorButtonsDisabled] = useState(false)
    const showSparkles = () => {
        const el = document.querySelector(`#${targetColorElId}`);
        party.sparkles(el, { count: party.variation.range(30, 50) })
    }
    const pickRandomColor = () => {
        let nextColor

        do {
            nextColor = pickFirstRandomColor()
        } while (nextColor.index === targetColor.index)
        playColorSound(nextColor)
        return nextColor
    }

    const handleColorClicked = (color) => {
        if (color.name === targetColor.name) {
            setColorButtonsDisabled(true)
            showSparkles()            
            setTimeout(() => {
                const nextColor = pickRandomColor()
                setTargetColor(nextColor)
                setShuffledColors(shuffleColors())
                setColorButtonsDisabled(false)
            }, 2000)
        } else {
            document.getElementById(koAudioElId).play()
        }        
    }


    return <>
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '4rem' }}>
            <div id={targetColorElId} style={ { height: '8rem', width: '80%', backgroundColor: targetColor.cssColor }}></div>
        </div>

        <audio id={koAudioElId} src="/audio/ko.mp3" preload="auto"></audio>
        {COLORS.map(c => <audio id={colorAudioId(c)} src={`/audio/${c.audio}`} preload="auto"></audio>)}
        
        <div style={ { display: 'flex', flexWrap: 'wrap', justifyContent: 'center', paddingTop: '4rem', paddingBottom: '4rem', height: '8rem' }}>
            {shuffledColors.map(c => 
                <Color color={c} disabled={colorButtonsDisabled} onClicked={e => handleColorClicked(c)} />)}
        </div>
    </>
}
export default ColorsGame
