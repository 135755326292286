import Week from "./Week";

const Home = () => {
    return <>
        {/*<div className="App-main">*/}
            {/* <p>
          <em>Os cuento los detalles de la celebración de mi primer cumpleaños (<strong>¡{remainingDays}!</strong>):</em>
        </p>
        <div className="balloons-container">
          <div className="balloon"></div>
          <div className="balloon"></div>
          <div className="balloon"></div>
          <div className="balloon"></div>
          <div className="balloon"></div>
        </div>
        <div>
          {instructions.map(i => {
            const { icon, text } = i;
            return <span className="App-main-instructions-item" key={icon}>
              <span>{icon}</span>
              <span dangerouslySetInnerHTML={{ __html: text }}></span>
            </span>
          })}
        </div>
        <div className='App-calendar-link'>
          <a href="cita-cumple-bruno.ics">Añade todo a tu calendario</a>
        </div> */}
        {/*</div>*/}
    </>
}

export default Home
