// import logo from './logo.svg';
import './App.css';
import party from "party-js";
import React, { useEffect } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./Layout";
import Home from "./Home";
import Private from "./Private";
/*
const instructions = [
  { icon: '🗓', text: 'Lo celebraremos el próximo sábado día 22 de julio.' },
  { icon: '🕚', text: 'Empezaremos a las 11:00.' },
  { icon: '📍', text: 'Pasaremos el día en <a href="https://unicobeachclub.com/" target="_blank" rel="noreferrer">Único Beach Club</a> (<a href="https://www.google.es/maps/place/%C3%9ANICO+BEACH+CLUB/@36.5024884,-4.6825619,17z/data=!3m1!4b1!4m6!3m5!1s0xd731f356ba1e4f9:0xa2bd327ae3619e44!8m2!3d36.5024884!4d-4.679987!16s%2Fg%2F11fmddx3d1?entry=ttu" target="_blank" rel="noreferrer">ver en Google Maps</a>).' },
  { icon: '🚘', text: "El sitio incluye aparcamiento gratuito <a href=\"https://www.google.es/maps/place/36%C2%B030'08.7%22N+4%C2%B040'49.3%22W/@36.5024434,-4.6802334,3a,75y,323.69h,92.82t/data=!3m6!1e1!3m4!1srGFj32m5gk7hkVOdAGz9Wg!2e0!7i16384!8i8192!4m5!3m4!8m2!3d36.50242!4d-4.680359!10e5?entry=ttu\" target=\"_blank\" rel=\"noreferrer\">justo al lado</a>; es probable que se llene sobre las 11:30, ¡intentad no llegar muy tarde!" },
  { icon: '🛏', text: 'Cada familia irá a una cama donde pasar el día (son para 3 personas y os invito yo).' },
  { icon: '🥂', text: 'Al llegar tendréis una botella de cava o jarra de sangría incluida para cada cama.' },
  { icon: '🐟', text: 'Sobre las 14:00 os invitaré a un almuerzo muy rico (comida y bebidas incluidas).' },
  { icon: '❗', text: '<strong>El resto de consumiciones corren por cuenta de cada cama.</strong>' },
  { icon: '🏊', text: 'El sitio tiene una piscina para refrescarse.' },
  { icon: '🏖', text: 'Estaremos a pie de playa para poder darnos un baño en el mar.' },
  { icon: '🕖', text: 'Terminaremos a las 19:00.' },
  { icon: '🎁', text: '¡Acepto regalos de cualquier tipo! 😃' }
]

const today = new Date()
const celebrationDate = new Date(2023, 6, 22)

const isCelebrationDay = today.getDate() === celebrationDate.getDate() && today.getMonth() === celebrationDate.getMonth() && celebrationDate.getFullYear() === celebrationDate.getFullYear()
const remainingDays = today < celebrationDate
  ? new Intl.RelativeTimeFormat('es').format(celebrationDate.getDate() - today.getDate(), 'day')
  : (isCelebrationDay ? 'ES HOY' : 'ya ha pasado')
*/
function App() {
  useEffect(() => {
    const app = document.querySelector('#app');
    party.confetti(app, { count: party.variation.range(150, 150) })
  })

  return (
    <div id="app" className="App">
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Layout />}>
                  <Route index element={<Home />} />
                  <Route path="private" element={<Private />} />
              </Route>
          </Routes>
      </BrowserRouter>
      <footer className="App-footer">
        <span>&copy; 2024 - Bruno Rubio</span>
      </footer>
    </div>
  );
}

export default App;
