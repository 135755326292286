import { Outlet } from "react-router-dom";

const Layout = () => {
    return (
        <>
            <header className="App-header">
                <img className="App-picture" src="/bruno-rubio.png" alt="Imagen de Bruno Rubio" /> 
                <h1>Bruno Rubio</h1>
            </header>
            <Outlet />
        </>
    )
};

export default Layout;
